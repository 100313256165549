"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStepOrder = exports.sortHotspotsForAnimation = exports.getNextNonEmptyAutoStep = exports.getNextNonEmptyIndexedStep = exports.getFirstNonEmptyStep = exports.isCropped = exports.hasBlur = void 0;
const types_1 = require("../types");
function hasBlur(step) {
    return (!!step.imageEditorState?.redaction &&
        step.imageEditorState?.redaction.length > 0);
}
exports.hasBlur = hasBlur;
function isCropped(step) {
    if (!step.imageEditorState?.crop || !step.size?.height || !step.size?.width) {
        return false;
    }
    return (step.imageEditorState.crop.height !== step.size.height ||
        step.imageEditorState.crop.width !== step.size.width ||
        step.imageEditorState.crop.x !== 0 ||
        step.imageEditorState.crop.y !== 0);
}
exports.isCropped = isCropped;
function getFirstNonEmptyStep(steps) {
    return steps.find(step => step.type !== types_1.StepType.Empty);
}
exports.getFirstNonEmptyStep = getFirstNonEmptyStep;
function getNextNonEmptyIndexedStep(steps, fromStep, allowRestart) {
    const fromStepIndex = steps.findIndex(s => s.id === fromStep.id);
    return (getFirstNonEmptyStep(steps.slice(fromStepIndex + 1)) ??
        (allowRestart ? getFirstNonEmptyStep(steps) : undefined));
}
exports.getNextNonEmptyIndexedStep = getNextNonEmptyIndexedStep;
function getNextNonEmptyAutoStep(steps, fromStep, options) {
    const { allowRestart, blockedAutoTransitions = new Set() } = options;
    // If fromStep is an image, targets can be hotspots.
    // If fromStep is an overlay, targets can be paths.
    // In the absence of targets, the next indexed step will always be used.
    const targets = (fromStep.type === types_1.StepType.Image
        ? fromStep.hotspots?.map(h => h.targetId)
        : fromStep.type === types_1.StepType.Overlay
            ? fromStep.paths
                ?.filter(path => path.pathType === 'step')
                .map(p => p.targetStepId)
            : []) ?? [];
    const nextIndexedStep = getNextNonEmptyIndexedStep(steps, fromStep, allowRestart);
    // Going down the list of targets, check if we're allowed to make the next
    // natural auto transition. If yes, return the auto step. If no, continue.
    // Note: a nullish target makes the auto transition the next indexed step.
    for (const t of targets) {
        const nextStep = t
            ? steps.find(({ id, type }) => id === t && type !== types_1.StepType.Empty)
            : nextIndexedStep;
        if (nextStep &&
            !blockedAutoTransitions.has(`${fromStep.id}-${nextStep.id}`))
            return nextStep;
    }
    // After we've exhausted all targets, simply returned the next indexed step,
    // if one exists.
    return nextIndexedStep;
}
exports.getNextNonEmptyAutoStep = getNextNonEmptyAutoStep;
// Sort hotspots/callouts so that they indexes attempt to match the type.
// This sort prioritizes hotspots over callouts so that hotspots are
// always animated.
// TODO: We could likely improve this even further by splitting hotspots and
// callouts entirely and then finding the closest ones of each type between
// steps.
function sortHotspotsForAnimation(a, b) {
    return (a.style ?? 'pulsating') > (b.style ?? 'pulsating') ? -1 : 1;
}
exports.sortHotspotsForAnimation = sortHotspotsForAnimation;
// Returns an array of steps in an Arcade in the order they would appear during
// autoplay with the following conditions:
// - Steps may repeat
// - The last step won't wrap around to the first again
// - Transitions between two particular steps can only be traversed once before
//     - we explore other targets
//     - we force next-indexed step when other targets are exhausted
function getStepOrder(steps, maxNumberOfSteps) {
    const sequence = [];
    const traversed = new Set();
    let step = getFirstNonEmptyStep(steps);
    while (!!step && sequence.length < maxNumberOfSteps) {
        sequence.push(step);
        const nextStep = getNextNonEmptyAutoStep(steps, step, {
            allowRestart: false,
            blockedAutoTransitions: traversed,
        });
        if (nextStep)
            traversed.add(`${step.id}-${nextStep.id}`);
        step = nextStep;
    }
    return sequence;
}
exports.getStepOrder = getStepOrder;
